import React, { useEffect, useState } from "react"
import Layout from "../../components/layout/layout"
import { useStaticQuery, graphql } from "gatsby"
import "./index.scss"
import Seo from "../../components/seo/seo"
import BlockSteps from "../../components/blockSteps/BlockSteps"
import BlockTopPage from "../../components/blockTopPage/blockTopPage"
import BlockSolution from "../../components/blockSolution/blockSolution"
import axios from "axios"
import config from "../../utils/config"
import { GatsbyImage, Placeholder } from "gatsby-plugin-image"
import BlockExperience from "../../components/blockExperience/BlockExperience"
import links from "../../utils/links"
import BlockMapFrance from "./Components/BlockMapFrance/BlockMapFrance"
import utils from "../../utils/utils"
import BlockSecurite from "./Components/BlockSecurite/BlockSecurite"
import BlockHeroDiagnostic from "../../components/blockHeroDiagnostic/BlockHeroDiagnostic"
import tracking from "../../utils/tracking"

const index = () => {
  const [campagne, setCampagne] = useState("")
  const [isTherecampagne, setIsThereCampagne] = useState(false)
  useEffect(() => {
    if (typeof window !== 'undefined' && localStorage.getItem("campaign") != null){
      setCampagne(localStorage.getItem("campaign"))
      setIsThereCampagne(true)
    }
  }, [])
  const data = useStaticQuery(graphql`
    query {
      allTaxonomyTermPositionsRegions {
        edges {
          node {
            name
            field_axe_
            field_axe_y
          }
        }
      }
      allNodePageNotreReseauParVille {
        nodes {
          field_nom_de_la_ville
          relationships {
            field_position_map {
              field_axe_
              field_axe_y
              name
            }
          }
        }
      }
      nodeHomepage {
        relationships {
          field_step_illustration_abonneme {
            weight
            field_titre
            relationships {
              field_picto {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
      allTaxonomyTermPositionsRegions {
        edges {
          node {
            name
            relationships {
              node__page_notre_reseau_par_ville {
                field_nom_de_la_ville
              }
            }
          }
        }
      }
      taxonomyTermBlockHeroDiagnostic {
        field_titre
        field_cover {
          alt
        }
        relationships {
          field_cover {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      nodePageNotreReseaux {
        field_seo_description
        field_seo_titre
        field_titre_carte_ {
          value
        }
        field_titre_block_liens {
          value
        }
        field_sous_titre_carte
        field_titre_block_securite_ {
          value
        }
        field_sous_titre_block_securite
        field_description_block_securite {
          value
        }
        field_description_securite_hide {
          value
        }
        relationships {
          field_carte_liens {
            field_titre_card
            field_label_boutton
            field_url_card
            field_image_illustration {
              alt
            }
            relationships {
              field_image_illustration {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          field_hero_header {
            field_titre_hero {
              value
            }
            field_sous_titre {
              value
            }
            field_description_hero {
              value
            }
            field_cover {
              alt
            }
            relationships {
              field_cover {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              field_mobile {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              field_tablette {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const allRegions = data.allTaxonomyTermPositionsRegions.edges

  const allVilles = data.allNodePageNotreReseauParVille.nodes.map(e => ({
    node: {
      field_axe_: e.relationships.field_position_map.field_axe_,
      field_axe_y: e.relationships.field_position_map.field_axe_y,
      name: e.field_nom_de_la_ville,
      region: e?.relationships?.field_position_map.name,
    },
  }))
  const dataPageReseau = data.nodePageNotreReseaux
  const dataCardExpertise =
    data.nodePageNotreReseaux.relationships.field_carte_liens
  const dataHeroDiagnostic = data.taxonomyTermBlockHeroDiagnostic

  let blockTopPage = utils.mapHeroHeader(data, "nodePageNotreReseaux")
  blockTopPage = {
    ...blockTopPage,
    wording: {
      ...blockTopPage.wording,
      description:
        data["nodePageNotreReseaux"].relationships.field_hero_header
          .field_description_hero.value,
    },
  }
  const blockSteps =
    data.nodeHomepage.relationships.field_step_illustration_abonneme
      ?.sort((a, b) => (a.weight > b.weight ? 1 : -1))
      .map(el => ({
        label: el.field_titre,
        icon: {
          path: el.relationships.field_picto.localFile.publicURL,
        },
      }))
  const blockExpertises = dataCardExpertise.map(e => ({
    title: e.field_titre_card,
    cta: e.field_label_boutton,
    link: e.field_url_card,
    cover:
      e.relationships.field_image_illustration.localFile.childImageSharp
        .gatsbyImageData,
    altCover: e.field_image_illustration.alt,
  }))

  useEffect(() => {
    /** Tracking */
    tracking.track("typeTemplate", "reseaux")
    tracking.track("page_name", dataPageReseau.field_seo_titre + (isTherecampagne ? "/" + campagne : ""))
    tracking.track("categories", "telesurveillance")
    //tracking.trackinit()
  }, [])

  const blockExpertise = () => {
    return (
      <div className="wrapper py-4">
        <div
          dangerouslySetInnerHTML={{
            __html: dataPageReseau.field_titre_block_liens.value,
          }}
          // className="d-f jc-c span-cg-15 txt-bold mb-3 ta-c"
          className="d-f jc-c span-c1 txt-bold mb-3 ta-c"
        ></div>
        <div className="d-f fd-r jc-c ai-c fd-md-c">
          {blockExpertises.map((el, idx) => (
            <div className="expertise" key={idx}>
              <GatsbyImage
                className="cover mb-5"
                image={el.cover}
                alt={el.altCover}
              />
              <span>{el.title}</span>
              <a
                href={el.link}
                className="mx-a mt-2 mb-2 btn-hover-c1-line btn-select-round with-line td-n w-s-n c-1"
              >
                {el.cta}
              </a>
            </div>
          ))}
        </div>
      </div>
    )
  }
  const [isMobile, setIsMobile] = useState(false)
  const [isTablette, setIsTablette] = useState(false)
  const [isDesktop, setIsDesktop] = useState(false)
  const resizeEvent = () => {
    const tablet = 1170
    const mobile = 768

    if (window.innerWidth > tablet) {
      setIsDesktop(true)
      setIsTablette(false)
      setIsMobile(false)
      return
    }
    if (window.innerWidth <= tablet && window.innerWidth >= mobile) {
      setIsDesktop(false)
      setIsTablette(true)
      setIsMobile(false)
      return
    }
    if (window.innerWidth <= mobile) {
      setIsDesktop(false)
      setIsTablette(false)
      setIsMobile(true)
      return
    }
  }
  useEffect(() => {
    resizeEvent()
    if (typeof window !== "undefined") {
      window.addEventListener("resize", () => {
        resizeEvent()
      })
    }
  }, [])

  return (
    <Layout isViewPromoOnHeader={false}>
      <Seo
        title={dataPageReseau.field_seo_titre}
        description={dataPageReseau.field_seo_description}
        linkCanonical="notre-reseau/"
      />
      <div className="block-page-aboutus">
        {/* Haut de la page */}
        <div className="pb-4 wrapper">
          <BlockTopPage data={blockTopPage} />
        </div>
        <div className="wrapper">
          <div className="py-4 reseaux-map">
            <div
              // className="d-f jc-c mb-1 txt-bold span-cg-15 ta-c"
              className="d-f fd-c jc-c mb-1 txt-bold span-c1 ta-c c-4"
              dangerouslySetInnerHTML={{
                __html:
                  data.nodePageNotreReseaux.field_titre_carte_.value +
                  `<p class="ff-gbook">${data.nodePageNotreReseaux.field_sous_titre_carte}</p>`,
              }}
            ></div>
            {/* <h3 className="d-f jc-c fs-16 ta-md-c ff-md">
              {data.nodePageNotreReseaux.field_sous_titre_carte}
            </h3> */}
            {allRegions?.length > 0 && allVilles?.length > 0 && (
              <BlockMapFrance regions={allRegions} villes={allVilles} />
            )}
          </div>
        </div>
        <div className="wrapper">
          <div className="mb-4">
            <BlockSteps
              title={data.nodeHomepage.field_titre_abonnement}
              data={blockSteps}
            />
          </div>
        </div>
        <BlockSecurite data={dataPageReseau} />
        {blockExpertise()}
        {/* <BlockHeroDiagnostic data={dataHeroDiagnostic} /> */}
        {/** BLOCK EXPERIENCE */}
        <BlockExperience />
      </div>
    </Layout>
  )
}

export default index
